const storageKey = {
    Auth: 'auth',
    User: 'user',
    CountLogin: 'countLogin',
    Estabelecimento: 'estabelecimento',
    EntradaProducao: 'entrada-producao',
    ControlePagina: 'controle-pagina',
    MensagemToast: 'mensagem-toast',
    Menu: 'menu'
}

export default storageKey;