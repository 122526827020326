import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class MultiploFatorService {

    enviarCodigo(username) {
        return axios.get(`${ ApiUrl.autenticacao }agricola/auth/v1/multiplo-fator/enviar-codigo/${username}`);
    }

    multiploFatorObrigatorio(username) {
        return axios.get(`${ ApiUrl.autenticacao }agricola/auth/v1/multiplo-fator/multiplo-fator-obrigatorio/${username}`);
    }

    validarCodigo(username, codigoAcesso) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        const data = {
            username: username,
            codigoAcesso: codigoAcesso
        }

        return axios.get(`${ ApiUrl.autenticacao }agricola/auth/v1/multiplo-fator/validar-codigo`, data, config);
    }
}

export default new MultiploFatorService();
