<template>
  <Toast />
	<div class="pages-body login-page flex flex-column">

    <div v-if="!mostrarPainelMultiploFator && !mostrarRecuperarSenhaModal" class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-4 py-1">
              <div class="grid col-12">
                <div class="col-12">
                  <img src="layout/images/logo-genesis-branca.png" alt="" style="height: 4.5rem">
                </div>
              </div>
            </div>


            <h4>Bem vindo.</h4>

            <div class="pages-detail mb-3 px-6">Informe seus dados de usuário para acessar o portal.</div>            
            
            <div>

              <div class="input-panel flex flex-column px-3">

                  <div class="dv-login">

                    <div class="card">
                      <Message severity="warn" v-if="message" @close="limparMensagem">{{ message }}</Message>
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                              <i class="pi pi-user"></i>
                          </span>
                          <span class="p-float-label">
                              <InputText v-model="user.username" :disabled="disabledComponents" autocomplete="off" class="p-inputtext p-component" placeholder="Usuário" @blur="loginEvent()"/>
                          </span>
                      </div>

                      <div class="p-inputgroup mt-3">
                          <span class="p-inputgroup-addon">
                              <i class="pi pi-lock"></i>
                          </span>
                          <span class="p-float-label">
                              <InputText v-model="user.password" :disabled="disabledComponents" autocomplete="off" class="p-inputtext p-component" type="password" placeholder="Senha" @blur="limparMensagem()"/>
                          </span>
                      </div>

                      <div v-if="mostrarMultiploFator && !disabledComponents" class="p-inputgroup mt-3">
                          <span class="p-inputgroup-addon">
                              <i class="pi pi-key"></i>
                          </span>
                          <span class="p-float-label">
                              <div class="p-inputgroup">
                                  <InputText v-model="user.codigoAcesso" class="p-inputtext p-component w-full" placeholder="Código de acesso" @blur="limparMensagem()"/>
                              </div>
                          </span>
                          
                      </div>

                      <div v-if="mostrarMultiploFator && !disabledComponents" class="col-12 dv-autenticacao-email p-inputgroup">
                        <i class="pi pi-send"> 
                          <a class="ml-2" @click="exibirMultiploFator" :title="user.emailCode">
                            Reenviar o código por e-mail
                          </a>
                        </i>
                        
                      </div>
                      
                      <div class="p-inputgroup">
                        <g-recaptcha :dataSitekey="siteKey" 
                                      dataLanguage="pt-BR" 
                                      :dataCallback="callback" 
                                      :dataExpiredCallback="expiredCallback"
                                      v-if="!mostrarMultiploFator"/>
                      </div>

                      
                    </div>  

                    <div class="p-inputgroup mt-1">
                      <div class="col-6 dv-resgate-senha">
                          <a @click="abrirRecuperarSenhaModal">Esqueceu a senha?</a>
                      </div>

                      <div class="col-6 dv-autenticacao-email">
                          <div class="p-inputgroup ml-3">
                            <i class="pi pi-mail"/>
                            <a v-if="!mostrarMultiploFator && !disabledComponents" @click="exibirMultiploFator">Validação via e-mail</a>
                          </div>
                      </div>
                    </div>

                    <Button label="ENTRAR" 
                        icon="pi pi-sign-in" 
                        class="p-button p-component mt-4" 
                        :disabled="(loading || disabledComponents)"
                        style="height: 40px;"
                        @click="login()" />
                    
                  </div>

                  <div class="col-12">
                      <span v-if="nodeEnv == 'development'" style="color: #e91e63;">{{ambiente}}</span>
                      <span v-if="nodeEnv == 'staging'" style="color: #2196f3;">{{ambiente}}</span>
                      <span v-if="nodeEnv == 'homologation'" style="color: #19a24d;">{{ambiente}}</span>
                    </div>
              </div>

            </div>
        </div>
    </div>

    <PainelMultiploFator v-if="mostrarPainelMultiploFator" :emailCode="user.emailCode" @ocultar="ocultarPainelMultiploFator"/>
</div>
<RecuperarSenhaModal :visivelProp="mostrarRecuperarSenhaModal" @fechar="fecharModalRecuperarSenha" />

</template>

<script>

import RecuperarSenhaModal from '../components/admin/RecuperarSenhaModal.vue';
import PainelMultiploFator from "../components/admin/PainelMultiploFator.vue";
import MultiploFatorService from '../service/MultiploFatorService';
import StorageService from '../service/StorageService';
import gRecaptcha from '../utilities/Recaptcha.vue';
import AuthService from '../service/security/AuthService';

export default {
  name: "Login",
  components: {
    RecuperarSenhaModal,
    PainelMultiploFator,
    gRecaptcha
},
  data() {
    
    return {
      loading: false,
      message: "",
      nodeEnv: process.env.NODE_ENV,
      ambiente: process.env.VUE_APP_AMBIENTE,
      mostrarRecuperarSenhaModal: false,
      mostrarPainelMultiploFator: false,
      mostrarMultiploFator: this.multiploFatorObrigatorio(),
      tokenCaptcha: '',
      doubleClick: false,
      limiteTentativalogin: 3,
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      countLogin: StorageService.getCountLogin(),
      usernameEnvioCodigoAnterior: null,
      disabledComponents: true,
      user: {
        username: null,
        password: null,
        codigoAcesso:null,
        emailCode: null
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    AuthService.logout();

    if (this.loggedIn) {
      this.$router.push("/login");
    }
  },

  mounted(){

    if(this.multiploFatorObrigatorio()){
      this.abrirMultiploFatorModal();      
    }

    setTimeout(() => {
      this.disabledComponents = false;
    }, "1000");
    
  },

  methods: {

    limparMensagem(){
        this.message = "";
    },

    login() {

      this.doubleClick = true;
      this.loading = true;

      if(this.validarCamposObrigatorios()){
        this.validarLogin();

      }else{
        this.doubleClick = false;
        this.loading = false;
      }


    },

    multiploFatorObrigatorio(){
      return StorageService.getCountLogin() >= this.limiteTentativalogin;
    },

    loginEvent(){
      this.limparMensagem();
    
      if(!this.user.username || this.multiploFatorObrigatorio()) return;
      
      if(!this.multiploFatorObrigatorio() &&
        !!this.usernameEnvioCodigoAnterior && 
        this.usernameEnvioCodigoAnterior == this.user.username  ) return;

      MultiploFatorService.multiploFatorObrigatorio(this.user.username)
          .then(response => {
              let isObrigatorio = response.data;
              if(isObrigatorio){
                this.usernameEnvioCodigoAnterior = this.user.username;
                StorageService.setCountLogin(this.limiteTentativalogin);
                this.mostrarMultiploFator = true;
                this.enviarCodigo();
              }
          })
          .catch(erro => { });
    },

    validarCamposObrigatorios(){

      if(!this.mostrarMultiploFator){
        if (this.tokenCaptcha == '') {
            this.message = "Captcha inválido";
            this.doubleClick = false;
            this.loading = false;
            this.countLogin++;
            StorageService.setCountLogin(this.countLogin);
            this.validarTrocaAutomaticaMultiploFator(); 
            return false;
        }

      }else{        
        if(!this.user.username || !this.user.password || !this.user.codigoAcesso){
          this.message = 'Favor informar o login, a senha e o código de acesso';
          this.doubleClick = false;
          this.loading = false;
          this.countLogin++;
          StorageService.setCountLogin(this.countLogin);
          return false;
        }
      }

      return true;
    },

    validarLogin(){
      this.$store.dispatch("auth/login", this.user).then(

        () => {
          StorageService.setCountLogin(0);
          this.$router.push("/");
        },

        (error) => {
          AuthService.logout();
          this.codigoAcesso = null;

          if (error.response.data != null) {
            
            this.message = error.response.data.message_error;
            StorageService.setCountLogin(StorageService.getCountLogin() + 1);
            this.validarTrocaAutomaticaMultiploFator();            

          } else {
              this.message = "Sistema indisponível";
          }
          this.doubleClick = false;
          this.loading = false;
        }
      );
    },

    validarTrocaAutomaticaMultiploFator(){
      if(this.multiploFatorObrigatorio() && !this.mostrarMultiploFator){
        this.mostrarMultiploFator = true;
        this.exibirMultiploFator();
        this.message = "Atenção! Validação por multiplo fator obrigatório."
      }
    },

    erroEstabelecimento(error) {
      this.message = error;
    },
    
    abrirRecuperarSenhaModal() {
      this.mostrarRecuperarSenhaModal = true;
    },

    fecharModalRecuperarSenha() {
        this.mostrarRecuperarSenhaModal = false;
    },
    
    abrirMultiploFatorModal() {
      this.mostrarPainelMultiploFator = true;
      this.mostrarMultiploFator = true;
    },

    ocultarPainelMultiploFator() {
        this.mostrarPainelMultiploFator = false;
    },

    exibirMultiploFator(){
      
      this.mostrarMultiploFator = true;
      this.codigoAcesso = null;
      
      if (!this.user.username) {
        this.message = "Atenção! Favor informar o login de acesso";
        return;
      }

      this.enviarCodigo();
      
    },

    enviarCodigo(){
      this.user.codigoAcesso = null;

      if(!this.user.username) return;

      MultiploFatorService.enviarCodigo(this.user.username)
          .then(response => {

              this.user.emailCode = response.data;

              this.abrirToast('success', 
                  'Sucesso', 
                  `E-mail enviado com sucesso para ${response.data}`, 
                  9000);

              if(StorageService.getCountLogin() < this.limiteTentativalogin){
                StorageService.setCountLogin(3);
              }

          })
          .catch(erro => {
              this.mostrarMultiploFator = false;
              this.user.emailCode = null;
              this.abrirToast('error', 
                  'Falha ao enviar o código de autenticação.', 
                  erro.response.data, 
                  9000);
          });
    },

    callback(tokenCaptcha) {
        this.tokenCaptcha = tokenCaptcha;
    },

    expiredCallback(){
      this.tokenCaptcha = '';
    },
            
    abrirToast(tipo, titulo, descricao, tempo) {
        this.$toast.add({
            severity: tipo,
            summary: titulo,
            detail: descricao,
            life: tempo
        });
    }

  },
};

</script>


<style scoped>
  .label a{
    color: rgba(70,130,180) !important;
  }
  span {  
    font-weight: bold;
  }
  .login-card{
    background: rgb(76, 146, 83) !important;
    box-shadow: -5px 5px 0 rgb(0, 0, 0, 0.6);
    -webkit-transition : box-shadow ease-out 0.8s;
    transition : box-shadow ease-out 0.8s;
    padding: 15px;
    border-radius: 10px;
    align-items: center; 
  }

  .dv-login{
    border: solid 1px #cecece;
    background-color: #fbfbfb !important;
    padding: 15px;
    border-radius: 10px;
    align-items: center; 
    margin-bottom: 10px !important;
  }

  .login-banner{
    border: solid 1px rgb(76,144,190) !important;
    border-radius: 10px 10px 0px 0px;
    background: rgb(76,144,190);
    padding: 5px 5px 0px 0px;
    text-align: center;
    color: #fff;
    font-size: 15px;
  
  }
  .login-content{
    border-radius: 0px 0px 10px 10px;
    background: #fff;

  }
  .profile-img-card {
    display: block;
    margin: 35px auto;  
  }

  .p-button{
    display: block;
    margin: 0 auto;
    width: 100%;
    border: none !important;
    background: rgb(2,136,209) !important;
    color: #FFF !important;
    box-shadow: 1px 3px 6px #666;
  }
  .p-button:hover {
    background: rgb(81, 170, 248) !important;
    color: #000 !important;
  }

  .p-button:focus-visible {
    /* remove default focus style */
    outline: none;
    /* custom focus styles */
    box-shadow: 0 0 2px 2px #5f5f5f;
    color: rgb(6, 43, 255);
  }

  .alert-danger{
    color: rgb(250, 6, 6);
    font-weight: 400;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .pages-body.login-page .pages-panel .pages-header {
      border: none;
      background: rgb(2,136,209) !important;
      box-shadow: 1px 3px 6px #666;
      max-width: 400px !important;
  }

  .dv-autenticacao-email { 
    text-align: left;
    color: #2196f3;
  }

  .dv-autenticacao-email a{
    color: #000;
    font-weight: 400;
    cursor: pointer;
    color: #2196f3;
    font-size: 12pt;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  .dv-resgate-senha{
    background: hsl(193deg 27% 83% /77%);  
    text-align: left;
    font-weight: 450;
  }

  .dv-resgate-senha a {
    color: #000;
    cursor: pointer;
  }

  .dv-code {
    border: #cecece 1px solid; 
    border-radius: 5px; 
    background: #f9f5ab;
  }

  .pages-body .pages-panel {
      opacity: 0.9 !important;
      max-width: 500px !important;
  }

</style>

