<template>
    <Toast />

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-4 py-1">
                <div class="grid col-12">
                    <div class="col-12">
                    <img src="layout/images/logo-genesis-branca.png" alt="" style="height: 4.5rem">
                    </div>
                </div>
            </div>

            <h4>Validação de Login</h4>
            
            <div class="input-panel flex flex-column px-3">

                <div class="dv-login">
                    <div class="p-inputgroup mt-2 mb-1">
                        <div class="col-2">
                            <img src="layout/images/login/security.png" alt="" style="width: 50px;">
                        </div>
                        <div class="col-10 pages-detail mb-3">
                            Para prosseguir com o seu login, enviaremos um código de validação para o seu e-mail.
                        </div>
                        
                    </div>

                    <Message severity="warn" v-if="message" @close="limparMensagem">{{ message }}</Message>

                    <div class="card">
                        <label for="username"><strong>Informe seu login de acesso<span class="ml-1" style="color:red">*</span></strong></label>
                        <div class="p-inputgroup mt-2">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText id="username" 
                                    v-model="username" 
                                    autocomplete="off" 
                                    :disabled="doubleClick"
                                    class="p-inputtext p-component" 
                                    placeholder="Login"
                                    @blur="limparMensagem()"/>
                            </span>
                        </div>

                        
                    </div>   
                    
                </div>
            </div>

            <div class="flex flex-column px-3">
                <Button label="ENVIAR CÓDIGO" 
                        icon="pi pi-send" 
                        class="p-button-text p-button-danger" 
                        :disabled="doubleClick"
                        style="height: 40px;"
                        @click="enviarCodigo()" />
            </div> 

            <div class="col-12 mt-3">
                <span v-if="nodeEnv == 'development'" style="color: #e91e63;">{{ambiente}}</span>
                <span v-if="nodeEnv == 'staging'" style="color: #2196f3;">{{ambiente}}</span>
                <span v-if="nodeEnv == 'homologation'" style="color: #19a24d;">{{ambiente}}</span>
            </div>

        </div>
    </div>

</template>

<script>
    import MultiploFatorService from '../../service/MultiploFatorService';

    export default {
        emits: ['ocultar'],
        props: {
            emailCode: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                message: "",
                username: null,
                usernameInvalida: false,
                doubleClick: false,
                limiteTentativalogin: 3,
                nodeEnv: process.env.NODE_ENV,
                ambiente: process.env.VUE_APP_AMBIENTE,
            }
        },

        methods: {

            limparMensagem(){
                this.message = "";
            },
    
            enviarCodigo() {
                if (!this.validarCampos() && !this.doubleClick) return;
                this.doubleClick = true;
             
                MultiploFatorService.enviarCodigo(this.username)
                    .then(response => {

                        //this.emailCode = response.data;

                        this.abrirToast('success', 
                            'Sucesso', 
                            `E-mail enviado com sucesso para ${response.data}`, 
                            9000);

                        this.doubleClick = false;
                        this.limparMensagem();
                        this.ocultarPainelMultiploFator();

                    })
                    .catch(erro => {
                        this.doubleClick = false;
                        this.message = erro.response.data;
                    });

            },

            validarCampos() {
                let isValid = true;

                if (!this.username) {
                    this.usernameInvalida = true;
                    isValid = false;
                }

                if (!isValid) {
                    this.message = "Ateção! Campo login obrigatório.";
                    return false;
                }

                return isValid;
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },

            ocultarPainelMultiploFator() {
                return this.$emit('ocultar');
            },
        }
    }
</script>

<style scoped>
  .label a{
    color: rgba(70,130,180) !important;
  }
  span {  
    font-weight: bold;
  }

  .dv-login{
    border: solid 1px #cecece;
    background-color: #fbfbfb !important;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px !important;
    text-align: justify !important;
  }
  
  .p-button{
    display: block;
    margin: 0 auto;
    width: 100%;
    border: none !important;
    background: rgb(2,136,209) !important;
    color: #FFF !important;
    box-shadow: 1px 3px 6px #666;
  }
  .p-button:hover {
    background: rgb(81, 170, 248) !important;
    color: #000 !important;
  }

  .p-button:focus-visible {
    /* remove default focus style */
    outline: none;
    /* custom focus styles */
    box-shadow: 0 0 2px 2px #5f5f5f;
    color: rgb(6, 43, 255);
  }

  .alert-danger{
    color: rgb(250, 6, 6);
    font-weight: 400;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }

  .pages-body.login-page .pages-panel .pages-header {
      border: none;
      background: rgb(2,136,209) !important;
      box-shadow: 1px 3px 6px #666;
      max-width: 400px !important;
  }
  
  .pages-body .pages-panel {
      opacity: 0.9 !important;
      max-width: 450px !important;
  }

</style>
