<template>
    <Dialog v-model:visible="modalExibir" 
            :breakpoints="{'960px': '75vw', '640px': '100vw'}" 
            :style="{width: '50vw'}" 
            header="Alerta" 
            :modal="true" 
            :closeOnEscape="false" 
            :closable="false">
        <div>
            <p>{{ dados.mensagem }}</p>

            <div class="p-inputgroup mt-4" v-if="!dados.linkTermosUso">
                <span>
                    <Checkbox v-model="termoUso" :binary="true" :class="[{'p-invalid': termoUsoInvalido}]" />
                    &nbsp; Concordo com os &nbsp;
                    <a :href="dados.linkTermosUso" target="_blank" class="termos">Termos de Uso</a>
                </span>
            </div>

            <div class="p-inputgroup mt-4" v-if="!dados.linkPoliticaPrivacidade">
                <span>
                    <Checkbox v-model="politicaPrivacidade" :binary="true" :class="[{'p-invalid': politicaPrivacidadeInvalido}]" />
                    &nbsp; Concordo com as &nbsp;
                    <a :href="dados.linkPoliticaPrivacidade" target="_blank" class="termos">Política de Privacidade</a>
                </span>
            </div>
        </div>
        <template #footer>
            <Button label="Salvar" icon="pi pi-sae" class="p-button p-component p-button-success" @click="atualizarTermoAceitePorUsuarioLogado"></Button>
        </template>
    </Dialog>
</template>

<script>
import TermoAceiteService from '../service/TermoAceiteService';
import StorageService from '../service/StorageService';

export default {
    data() {
        return {
            dados: {},

            termoUso: false, 
            termoUsoInvalido: false,
            politicaPrivacidade: false,
            politicaPrivacidadeInvalido: false,

            urlTermoUso: '',
            urlPoliticaPrivacidade: '',

            modalExibir: false,
        };
    },

    mounted() {
        if(!!StorageService.getAuth()) this.verificarAceitePorUsuarioLogado();
    },

    methods: {
        verificarAceitePorUsuarioLogado() {
            TermoAceiteService.verificarAceitePorUsuarioLogado()
                .catch(() => {
                    this.buscarUltimoTermo();
                    this.modalExibir = true;
                });
        },

        buscarUltimoTermo() {
            TermoAceiteService.buscarUltimoTermo()
                .then(({ data }) => {
                    this.dados = data;
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível buscar os termos.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { exception } = error.response.data;
                        toast["detail"] = exception;
                    }

                    this.$toast.add(toast);
                });
        },

        validarCamposObrigatorios() {
            const termos = [];

            if (!this.dados.linkTermosUso && this.termoUso == false) {
                termos.push("Termo de Uso");
                this.termoUsoInvalido = true;
            }

            if (!this.dados.linkPoliticaPrivacidade && this.politicaPrivacidade == false) {
                termos.push("Politica de Privacidade");
                this.politicaPrivacidadeInvalido = true;
            }

            if(termos.length > 0) {
                let mensagem = `Necessário concordar com o termo "${ termos[0] }" para continuar acessando o sistema.`;

                if(termos.length > 1) {
                    mensagem = termos.join("\", \"");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                    mensagem = `Necessário concordar com os termos "${ mensagem }" para continuar acessando o sistema`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar o contrato.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return termos.length === 0;
        },

        atualizarTermoAceitePorUsuarioLogado() {
            this.loading = true;
            if (!this.validarCamposObrigatorios()) return;

            TermoAceiteService.atualizarTermoAceitePorUsuarioLogado()
                .then(() => {
                    this.modalExibir = false;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Termos',
                        detail: "O termo foi salvo com sucesso!", 
                        life: 7500
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        }
    },
}
</script>

<style>
    .termos {
        text-decoration: underline;
    }

    .p-button:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.38) !important;
        opacity: 1;
    }
</style>

