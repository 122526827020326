import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class TermoAceiteService {

    atualizarTermoAceitePorUsuarioLogado() {
        const config = { headers: { 'content-type': 'application/json' } }
        return axios.put(`${ ApiUrl.autenticacao }agricola/auth/v1/termo-aceite`, undefined, config);
    }

    buscarUltimoTermo() {
        return axios.get(`${ ApiUrl.autenticacao }agricola/auth/v1/termo-aceite`);
    }
    
    verificarAceitePorUsuarioLogado() {
        return axios.get(`${ ApiUrl.autenticacao }agricola/auth/v1/termo-aceite/verificar-por-usuario-logado`);
    }
}

export default new TermoAceiteService();